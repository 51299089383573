<template>
  <div>
    <goto-talking></goto-talking>
  </div>
</template>

<script>
import GotoTalking from "@/components/GotoTalking";
export default {
  components: { GotoTalking },
};
</script>