<template>
  <div class="go-to-talking-box">
    <div class="room-hd">
      <div class="hd-back" @click="goback">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="hd-name">{{ $route.query.email }}</div>
    </div>
    <div class="room-bd">
      <div id="msgBody">
        <div v-for="(item, index) in messageList" :key="index">
          <div v-if="item.toType == 201 ? true : false">
            <div style="text-align: center">
              {{ $utils.formatDataTime(item.createAt) }}
            </div>
            <div style="float: right">
              <p class="bd-msg">
                <span class="bd-info">{{ item.content }} </span>
                <el-avatar shape="square" style="width: 50px">
                  vendor
                </el-avatar>
              </p>
            </div>
            <br style="clear: both" />
          </div>
          <div v-else>
            <div style="text-align: center">
              {{ $utils.formatDataTime(item.createAt) }}
            </div>
            <div>
              <p class="bd-msg">
                <el-avatar shape="square"> user </el-avatar
                ><span class="bd-infos">{{ item.content }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="msgFooter">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="inputMessage"
          @keyup.enter.native="sendMessage()"
          style="outline: none"
        ></el-input>
        <div>
          <el-button class="sendBnt" type="primary" @click="sendMessage()" round
            >发送</el-button
          >
        </div>
        <div v-show="false">
          <el-button
            class="sendBnt"
            type="primary"
            @click="getMessageList"
            round
            >获取消息纪录</el-button
          >
          <el-dialog
            :modal-append-to-body="true"
            :append-to-body="true"
            :visible.sync="innerVisible"
            :show-close="false"
            class="talking-room"
          >
            <div class="room-hd">
              <div class="hd-back" @click="gobackinner">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="hd-name">{{ user }}</div>
            </div>
            <div class="room-bd">
              <div id="msgBody">
                <div v-for="(item, index) in messageList" :key="index">
                  <div v-if="item.toType == 101 ? false : true">
                    <div style="float: right">
                      <p class="bd-msg">
                        <span class="bd-info">{{ item.content }}</span
                        ><el-avatar shape="square" style="width: 50px">
                          vendor
                        </el-avatar>
                      </p>
                    </div>
                    <br style="clear: both" />
                  </div>
                  <div v-else>
                    <div>
                      <p class="bd-msg">
                        <el-avatar shape="square"> user </el-avatar
                        ><span class="bd-infos">{{ item.content }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RegisterEasemodUser, AddImChat, GetImChatList } from "@/utils/api";
import { conn } from "@/utils/WebIM.js";
//格式化时间
import { formatDate } from "@/utils";
var WebIM = window.WebIM;
export default {
  data() {
    return {
      dialogTableVisible: false,
      innerVisible: false,
      user: "", //自己应用下的用户id
      pwd: "123", //用户密码
      inputMessage: "",
      messageList: [], //消息
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("vendor_user")).userId;
    // 进入聊天室
    if (localStorage.getItem("vendor_user")) {
      this.dialogTableVisible = true;
      RegisterEasemodUser().then((res) => {
        console.log("RegisterEasemodUser-res", res);
      });
      this.loginIM();
      this.getImChatList();
    } else {
      this.dialogTableVisible = false;
      WebIM.conn.close();
      this.$router.push("/v2/login");
    }
    console.log(this.user, "user");
    document.onkeydown = (e) => {
      e = window.event || e;
      if (e.code == "Enter" || e.code == "enter") {
        this.sendMessage();
      }
    };
  },
  methods: {
    //登陆
    loginIM() {
      var options = {
        user: this.user,
        pwd: this.pwd,
        appKey: WebIM.config.appkey,
        success: function (res) {
          console.log(res);
          console.log("成功");
        },
        error: function (err) {
          console.log(err);
        },
      };
      conn.open(options);
      this.getMessage();
    },
    //发送消息
    sendMessage() {
      if (!this.inputMessage || !this.inputMessage.replace(/&nbsp;|\s/g, "")) {
        this.$message.info("发送内容不能为空!");
        return;
      }
      let that = this;
      let contentMsg = that.inputMessage;
      let toID = this.$route.query.vid; //收信息用户id
      // let toID = "62a842580a0bbb288a244db9"; //收信息用户id
      let id = conn.getUniqueId(); // 生成本地消息id
      let msg = new WebIM.message("txt", id); // 创建文本消息
      msg.set({
        msg: contentMsg, // 消息内容
        to: toID, // 接收消息对象（用户id）
        chatType: "singleChat", // 设置为单聊
        success: function (id, serverMsgId) {
          console.log("成功发送消息", toID);
          that.sendMessageSuccessful(contentMsg, toID, "txt", id, serverMsgId);
        },
        fail: function (e) {
          console.log("发送消息失败", e);
        },
      });
      conn.send(msg.body);
      that.inputMessage = null;
    },
    // 点击获取聊天记录
    getMessageList() {
      this.innerVisible = true;
      this.getImChatList();
    },
    // 获取聊天记录
    getImChatList() {
      GetImChatList({
        visitorId: this.$route.query.vid, //咨询者id
        page: 1,
        pageSize: 20,
      }).then((res) => {
        // console.log("GetImChatList-res", res);
        this.messageList = res.data.rows.reverse();
      });
    },
    // 退出客服聊天
    goback() {
      WebIM.conn.close();
      this.dialogTableVisible = false;
      this.$router.go(-1);
    },
    // 关闭聊天记录
    gobackinner() {
      WebIM.conn.close();
      this.innerVisible = false;
    },
    //成功发送消息，进行消息加入到聊天信息数组中
    sendMessageSuccessful(data, toID, type) {
      console.log("存储信息中》》》》》");
      let userMsg = {};
      userMsg.to = toID;
      userMsg.from = this.user;
      userMsg.content = data;
      userMsg.time = formatDate(
        new Date(new Date().getTime()),
        "yyyy-MM-dd hh:mm"
      );
      userMsg.msgType = type;
      //存储信息
      this.messageList.push(userMsg);
      console.log(this.messageList, "message", this.messageList[0].content);
      AddImChat({
        visitorId: this.$route.query.vid, //咨询者id
        content: this.messageList[this.messageList.length - 1].content,
        msgType: this.messageList[this.messageList.length - 1].msgType,
      }).then((res) => {
        console.log("AddImChat-res", res);
        this.getImChatList();
      });
    },
    // 集成收到文本信息方法
    getMessage() {
      let that = this;
      conn.listen({
        onOpened: function (message) {
          console.log("用户已上线", message); // 连接成功
        },
        onTextMessage: function (message) {
          console.log("hahaha收消息");
          console.log("收到文本信息", message);
          let date = formatDate(
            new Date(new Date().getTime()),
            "yyyy-MM-dd hh:mm"
          );
          let value = {};
          // 普通文本信息
          value = {
            msgType: "text",
            content: message.data,
            to: message.to,
            from: message.from,
            time: date,
          };
          that.messageList.push(value); // 添加到聊天信息数组中
          console.log("value", value);
        },
      });
    },
  },
};
</script>

<style lang="scss">
.go-to-talking-box {
  width: 100%;
  min-height: 875px;
  background: #fff;
  .room-hd {
    position: sticky;
    top: 0;
    height: 60px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .hd-back {
      font-size: 20px;
      color: #333;
      cursor: pointer;
    }
    .hd-name {
      font-size: 16px;
      font-weight: normal;
      color: #333;
      margin: auto;
    }
  }
  .room-bd {
    #msgBody {
      width: 100%;
      height: 751px;
      background: #f1f1f1;
      overflow: auto;
      .bd-msg {
        margin-top: 10px;
        padding: 0 10px 0 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bd-info {
          max-width: 460px;
          border-radius: 4px;
          background: #ff6e6e;
          color: #f6f6f6;
          padding: 8px;
          display: inline-block;
          margin-right: 15px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent #ff6e6e;
          }
        }
        .bd-infos {
          border-radius: 4px;
          background: #ffffff;
          color: #000000;
          padding: 8px;
          display: inline-block;
          margin-left: 15px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #ffffff transparent transparent;
          }
        }
      }
    }
    #msgFooter {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .sendBnt {
        margin-left: 10px;
      }
    }
  }
}
</style>